@class-prefix-toast: ~'fa-toast';

.@{class-prefix-toast}-mask {
  z-index: 99999999 !important;

  .@{class-prefix-toast}-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .@{class-prefix-toast}-main {
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    // max-width: 204px;
    max-width: 400px;
    max-height: 70%;
    overflow: auto;
    color: white;
    word-break: break-all;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    pointer-events: all;
    font-size: var(--adm-font-size-7);
    line-height: 1.5;
    box-sizing: border-box;
    text-align: initial;
  }
  .@{class-prefix-toast}-main-text {
    padding: 12px;
    // padding: 16px 24px;
    min-width: 0px;
  }
  .@{class-prefix-toast}-main-icon {
    padding: 35px 12px;
    min-width: 150px;
    .@{class-prefix-toast}-icon {
      text-align: center;
      margin-bottom: 8px;
      font-size: 36px;
      line-height: 1;
    }
  }
}

.@{class-prefix-toast}-loading {
  --size: 48px;
  margin: 0 auto 8px;
}
