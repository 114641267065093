/**
 * index
 *
 * @author fukui
 */
@import './mixins/index';
@import './base/index';
//@import './font/index';
@import './reset/index';
@import './animation/index';

@ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
@all-transition-base: all 0.3s @ease-in-out-cubic;
@all-transition-active: all 0.1s linear;
@theme-common-01: #417ff9;
@theme-common-02: #608dfa;
@theme-common-03: #1f72f1;
@folo-primary-color: #0ab8ff;
@primary-color: #22d5a3;
@yigo-primary-color: #8a4afb;
@yigo-primary-gradient-color: linear-gradient(90deg, #6c23fe 0%, #c73fe8 58%, #f9acb6 100%);
@arhbo-primary-color: #58be69;
@arhbo-primary-gradient-color: linear-gradient(90deg, #58be69 0%, #5adf70 100%);
@yichat-primary-color: #f9b70d;
@yichat-primary-gradient-color: linear-gradient(136deg, #f9cf0d 0%, #ff4700 100%);

html[lang='ar'] {
  .ar-reverse-image {
    transform: rotateY(180deg);
  }
}
